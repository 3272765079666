import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import PropTypes from 'prop-types'
import React from 'react'
import Columns from '../components/Columns'
import Content from '../components/Content'
import EventTeaser from '../components/EventTeaser'
import Headline from '../components/Headline'
import Stage from '../components/Stage'
import Default from '../templates/default'

export interface Offer {
    title: string
    url: string
    markAsTopEvent: boolean
    category: string
    price: string
    info: {
        info: string
    }
    additionalInfo: string
    stageImage: {
        localFile: {
            childImageSharp: {
                fluid: FluidObject
            }
        }
    }
    content: {
        content: string
    }
}

interface Offers extends Array<Offer> {}

export interface UnsereAngeboteProps {
    data: {
        stageImage: {
            childImageSharp: {
                fluid: FluidObject
            }
        }
        offers: {
            nodes: Offers
        }
    }
}

const UnsereAngebote = ({ data }: UnsereAngeboteProps): React.ReactElement => {
    return (
        <Default>
            <Content>
                <Stage image={data.stageImage.childImageSharp} small />

                <Headline type="h2" text="TOP EVENTS" horizontalLine />

                <Columns count={3} marginBottom={60}>
                    {data.offers.nodes
                        .filter((offer: Offer): Offer[] => offer.markAsTopEvent === true)
                        .map(
                            (offer: Offer): JSX.Element => (
                                <EventTeaser
                                    key={offer.url}
                                    title={offer.title}
                                    subtitle={offer.category}
                                    additionalInfo={offer.info.info}
                                    price={offer.price}
                                    image={offer.stageImage.localFile.childImageSharp.fluid}
                                    linkTarget={`/unsere-angebote/${offer.url}/`}
                                />
                            ),
                        )}
                </Columns>

                <Headline type="h2" text="ALLE EVENTS" horizontalLine />

                <Columns count={3}>
                    {data.offers.nodes.map(
                        (offer: Offer): JSX.Element => (
                            <EventTeaser
                                key={offer.url}
                                title={offer.title}
                                subtitle={offer.category}
                                additionalInfo={offer.info.info}
                                price={offer.price}
                                image={offer.stageImage.localFile.childImageSharp.fluid}
                                linkTarget={`/unsere-angebote/${offer.url}/`}
                            />
                        ),
                    )}
                </Columns>
            </Content>
        </Default>
    )
}

UnsereAngebote.propTypes = {
    children: PropTypes.any,
}

UnsereAngebote.defaultProps = {}

export const query = graphql`
    query {
        stageImage: file(relativePath: { eq: "stage/unsere-angebote.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200, maxHeight: 250, quality: 75, cropFocus: CENTER) {
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                }
            }
        }
        offers: allContentfulOffer {
            nodes {
                title
                url
                markAsTopEvent
                category
                price
                info {
                    info
                }
                additionalInfo
                stageImage {
                    file {
                        url
                        fileName
                        contentType
                    }
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 390, maxHeight: 390, quality: 75, cropFocus: CENTER) {
                                aspectRatio
                                src
                                srcSet
                                srcWebp
                                srcSetWebp
                                sizes
                            }
                        }
                    }
                }
                content {
                    raw
                }
            }
        }
    }
`

export default UnsereAngebote
