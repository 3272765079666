import { css, StyleSheet } from 'aphrodite/no-important'
import { Link } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'
import ReactMarkdown from 'react-markdown'

export interface EventTeaserProps {
    image: FluidObject
    title: string
    subtitle: string
    additionalInfo?: string
    price: string
    linkTarget: string
}

const EventTeaser: React.FC<EventTeaserProps> = ({
    image,
    title,
    subtitle,
    additionalInfo,
    price,
    linkTarget,
}: EventTeaserProps): JSX.Element => (
    <Link to={linkTarget} className={css(styles.EventTeaser)}>
        <Img className={css(styles.image)} fluid={image} />

        <div className={css(styles.content)}>
            <div className={css(styles.title)}>{title}</div>
            <div className={css(styles.subtitle)}>{subtitle}</div>
            <ReactMarkdown className={css(styles.additionalInfo)} source={additionalInfo} />
            <div className={css(styles.price)}>{price}</div>
        </div>
    </Link>
)

const styles = StyleSheet.create({
    EventTeaser: {
        display: 'block',
        border: '1px solid #000',
        color: '#000',
        textDecoration: 'none',
        transition: 'box-shadow 0.2s ease-in-out',

        ':hover': {
            boxShadow: '0 4px 8px 0 rgba(0,0,0,0.5)',
        },
    },
    image: {
        width: '100%',
        height: 175,
        objectFit: 'cover',

        '@media (max-width: 1023px)': {
            height: 250,
        },
    },
    content: {
        padding: 20,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '20px 20px 80px 20px',
        gridRowGap: 20,
    },
    title: {
        fontSize: 16,
        textTransform: 'uppercase',
    },
    subtitle: {
        fontSize: 16,
        color: '#deb4ba',
    },
    additionalInfo: {
        fontWeight: 300,
        whiteSpace: 'pre-line',
        paddingBottom: 25,
        borderBottom: '1px solid #000',
        flexDirection: 'column',
    },
})

export default EventTeaser
